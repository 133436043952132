import { SelectInput } from 'shared-library';
import React from 'react';
import { Column, Row } from '../Layout';
import { AmountToPay } from '../AmountToPay';

const PrepaidPayment = ({ transaction, setTransaction }) => {
  return (
    <Row>
      <Column>
        <SelectInput
          label="Prepaid Account"
          options={[{ label: 'None available', value: 'None available' }]}
          onChange={(option) => setTransaction({ ...transaction, paymentMethod: option.value })}
        />
      </Column>
      <AmountToPay transaction={transaction} setTransaction={setTransaction} />
    </Row>
  );
};

export default PrepaidPayment;
